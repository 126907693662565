<template>
  <b-modal ref="contact_request" id="contact_request" title="" centered hide-footer>
    <div class="col-md-12">

        <div class="row col-md-12">
            <h4 class="page-title col-md-3">Subject</h4>
            <p class="col-md-9">: {{contact_request_data.subject}}</p>
        </div>
        <div class="row col-md-12">
            <h4 class="page-title col-md-3">Message</h4>
            <p class="col-md-9">: {{contact_request_data.message}}</p>
        </div>
        <br/>
        <div class="row col-md-12">
            <h4 class="page-title col-md-3">Sender</h4>
            <p class="col-md-9">: {{contact_request_data.name}}</p>
        </div>
        <div class="row col-md-12">
            <h4 class="page-title col-md-3">Sender Email</h4>
            <p class="col-md-9">: {{contact_request_data.email}}</p>
        </div>

        <div class="row" centered>
            <div class="col-md-5"></div>
            <button type="button"
              class="col-md-2 btn btn-gradient-primary me-2" @click="hide()">Close</button>
            <div class="col-md-5"></div>
        </div>
    </div>
  </b-modal>
</template>
<script>
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import { required, numeric } from "vuelidate/lib/validators";
import moment from "moment";

export default {
    components: {
      Multiselect,
      DateRangePicker,
    },
    data() {
        return {
          moment,
          opd:null,
          contact_request_data:null,
        }
    },
    mounted(){
    },
    methods: {
        ...mapActions("hospital",[]),
        show() {
            this.$refs.contact_request.show();
        },

        hide() {
            this.$emit('hide', true)
            this.$refs.contact_request.hide();
        },

        clearData() {
            this.contact_request = null
        },

        receiveData(contact_request) {
            this.contact_request_data = contact_request
        },
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
